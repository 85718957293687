<template>
    <div>
        <button class="btn btn-primary" id="create-patient-btn" @click="patientStore.modal.show()">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                <path d="M11 13V11.6667C11 10.9594 10.719 10.2811 10.219 9.78105C9.71885 9.28095 9.04058 9 8.33333 9H3.66667C2.95942 9 2.28115 9.28095 1.78105 9.78105C1.28095 10.2811 1 10.9594 1 11.6667V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.0026 6.33333C7.47536 6.33333 8.66927 5.13943 8.66927 3.66667C8.66927 2.19391 7.47536 1 6.0026 1C4.52984 1 3.33594 2.19391 3.33594 3.66667C3.33594 5.13943 4.52984 6.33333 6.0026 6.33333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.6641 4.33594V8.33594" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.6641 6.33594H11.6641" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="ms-2">{{ langStore.lang.views.invite_patient }}</span>
        </button>
        <CreatePatientModal />
    </div>
</template>
<script setup>
import { useLangStore } from '../../stores/lang';
import CreatePatientModal from './CreatePatientModal.vue';
import { usePatientStore } from '../../stores/patient';
import { onMounted } from 'vue';
import { initIntlTelInput } from '../../utils/init-intl-tel-input';
const patientStore = usePatientStore();
const props = defineProps(['lang', 'nhsEnabled', 'physioEnabled', 'specialties', 'clinicalTeams'])
const langStore = useLangStore();

langStore.lang = props.lang
patientStore.nhsEnabled = props.nhsEnabled
patientStore.physioEnabled = props.physioEnabled
patientStore.specialtyOptions = props.specialties
patientStore.clinicalTeams = props.clinicalTeams

onMounted(() => {
    initIntlTelInput();
})
</script>
